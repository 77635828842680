<template>
	<div>
		<div class="searchfor">
			<div class="input">
				<el-input class="input-with-select" placeholder="请输入赛事名称" v-model="info.matchName"
					style="font-size: 15PX;" placeholder-class="custom-placeholder">
					<el-button slot="append" @click="searchfor" style="font-size: 17PX;">搜索</el-button>
				</el-input>
			</div>
		</div>
		<div class="screenbox">
			<img src="./image/screen.png" alt="">
			<p style="margin-right: 10PX;">筛选赛事</p>
			<el-link type="primary" :underline="false" @click="toggleFilterOptions" v-if="!showFilterOptions">展开 <i
					class="el-icon-arrow-down el-icon--right"></i> </el-link>
			<el-link type="primary" :underline="false" @click="toggleFilterOptions" v-else>收起 <i
					class="el-icon-arrow-up el-icon--right"></i> </el-link>
		</div>
		<div v-if="showFilterOptions">
			<div class="nian">
				<p>赛事年份</p>
				<div class="select">
					<!-- <el-select v-model="info.matchYear" placeholder="请选择" @change="match" clearable>
						<el-option v-for="item in time" :key="item" :label="item" :value="item">
						</el-option>
					</el-select> -->
					<a-select option-label-prop="label"  placeholder="请选择" v-model="info.matchYear" allowClear>
						<a-select-option v-for="item in time" :key="item" :value="item" :label="item">
							<a-icon type="check" v-if="item===info.matchYear"/> 
							{{item}}
						</a-select-option>
					</a-select>
				</div>
			</div>
			<div class="xian"></div>
			<div class="nian">
				<p>赛事月份</p>
				<div class="select">
					<!-- <el-select v-model="info.matchMonth" placeholder="请选择" @change="match1" clearable>
						<el-option v-for="item in month" :key="item.value" :label="item.name" :value="item.value">
						</el-option>
					</el-select> -->
					<a-select option-label-prop="label"  placeholder="请选择" v-model="info.matchMonth" allowClear>
						<a-select-option v-for="item in month" :key="item.value" :value="item.value" :label="item.name">
							<a-icon type="check" v-if="item.value===info.matchMonth"/> 
							{{item.name}}
						</a-select-option>
					</a-select>
				</div>
			</div>
			<div class="xian"></div>
			<div class="nian">
				<p>赛事地点</p>
				<div class="select">
					<a-select option-label-prop="label"  placeholder="请选择" v-model="info.matchAddressDistrict" allowClear>
						<a-select-option v-for="item in matchAddresslist" :key="item.itemValue" :value="item.itemValue" :label="item.itemText">
							<a-icon type="check" v-if="item.itemValue===info.matchAddressDistrict"/> 
							{{item.itemText}}
						</a-select-option>
					</a-select>
				</div>
			</div>
			<div class="xian"></div>
			<div class="nian" style="margin-top: 16PX;">
				<p>级别</p>
			</div>

			<div class="event">
				<div v-for="(item,index) in eventList" :key="index"
					:class="levelindex == index + 1 ? 'eventbut eventbut2':'eventbut'"
					@click="getevent(index + 1,item)">
					{{item.itemText}}
				</div>
			</div>

			<div class="xianm">
				<div :class="columnIndex == 1 ? 'name name2':'name'" @click="column(1,'1784398050100674562','summer_sports')">夏季奥运项目
				</div>
				<div :class="columnIndex == 2 ? 'name name2':'name'" @click="column(2,'1784475618153758722','winter_sports')">冬季奥运项目
				</div>
				<div :class="columnIndex == 3 ? 'name name2':'name'" @click="column(3,'1784478645149401089','non_austrian_sports')">非奥运项目
				</div>
			</div>

			<div class="xiaoxian" v-if="columnIndex != 0">
				<div class="name" @click="getsports('','')" :style="{'color':sportsindex == '' ? '#3C75C2':'#000' }">全部
				</div>
				<div class="name" v-for="(item,index) in sportsList" :key="index" @click="getsports(index + 1,item)"
					:style="{'color':sportsindex == index + 1 ? '#3C75C2':'#000' }">
					{{item.item_text}}
				</div>
			</div>
		</div>

	<!-- 	<div class="saishi">
			<div class="saishixx" v-for="(item,index) in data" :key="index" @click="getdetails(item)">
				<div class="introduction">
					<img class="img" :src="item.image" alt="">
				</div>
				<div class="saishibox">
					<div class="specific-box">
						<div class="name-container">
							<p class="name">{{item.matchName}}</p>
						</div>
						<div class="line"></div>
						<div class="specific">
							<img class="img" src="../../assets/image/date3.png" alt="">
							<div class="information">
								<div class="information-title">比赛时间</div>
								<div class="information-date">{{item.matchDateStart}} 至 {{item.matchDateEnd}}</div>
							</div>
						</div>
						<div class="line"></div>
						<div class="specific">
							<img class="img" src="../../assets/image/addes3.png" alt="">
							<div class="information">
								<div class="information-title">比赛地点</div>
								<div class="information-date">{{item.matchAddressStadium}}
								</div>
							</div>
						</div>
						<div class="line"></div>
						<div class="specific">
							<img class="img" src="../../assets/image/status.png" alt="">
							<div class="information" >
								<div class="information-title">赛事状态</div>
								<div class="information-date">{{item.matchRunStatus}}
								</div>
							</div>
						</div>
					</div>
					<img src="./image/saisback.png" alt="">
				</div>
			</div>
		</div> -->
		
		<div class="course-box">
			<div class="course" v-for="(item,index) in data" :key="index" @click="getdetails(item)">
				<div class="introduction">
					<img class="img" :src="item.image" alt="">
				</div>
				<div class="name-container">
					<p class="name">{{item.matchName}}</p>
				</div>
				<div class="line" style="margin: 0;"></div>
				<div class="specific-box">
					<div class="specific">
						<img class="img" src="../../assets/image/date3.png" alt="">
						<div class="information">
							<div class="information-title">比赛时间</div>
							<div class="information-date" v-if="item.matchDateStart == item.matchDateEnd">{{item.matchDateStart | format}}</div>
							<div class="information-date" v-else>{{item.matchDateStart | format}} 至 {{item.matchDateEnd | format}}</div>
						</div>
					</div>
					<div class="line"></div>
					<div class="specific">
						<img class="img" src="../../assets/image/addes3.png" alt="">
						<div class="information">
							<div class="information-title">比赛地点</div>
							<div class="information-date">{{item.matchAddressStadium || '-' }}</div>
						</div>
					</div>
					<div class="line"></div>
					<div class="specific" style="padding-bottom: 5PX;">
						<img class="img" src="../../assets/image/status.png" alt="">
						<div class="information">
							<div class="information-title">赛事状态</div>
							<div class="information-date">{{item.matchRunStatus || '-' }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="paging">
			<pagination :total="total" @currentchange="currentchange" :type="2" :go="12" :pageSize="12" :pageNo="info.pageNo"></pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		props: {
			id: {
				type: [Number, String],
				default: ''
			},
			pid: {
				type: [Number, String],
				default: ''
			},
		},
		data() {
			return {
				yearindex: '',
				themoonindex: '',
				natureindex: '',
				levelindex: '',
				columnIndex: 0,
				natureList: [],
				eventList: [{
						itemText: '国际体育赛事',
						itemValue: 4
					},
					{
						itemText: '全国体育赛事',
						itemValue: 3
					},
					{
						itemText: '跨省市体育赛事',
						itemValue: 2
					},
					{
						itemText: '市级体育赛事',
						itemValue: 1
					},
					{
						itemText: '区级体育赛事 ',
						itemValue: 0
					}
				],
				month: [{
						name: '一月',
						value: '01'
					},
					{
						name: '二月',
						value: '02'
					},
					{
						name: '三月',
						value: '03'
					},
					{
						name: '四月',
						value: '04',
					},
					{
						name: '五月',
						value: '05'
					},
					{
						name: '六月',
						value: '06'
					},
					{
						name: '七月',
						value: '07'
					},
					{
						name: '八月',
						value: '08'
					},
					{
						name: '九月',
						value: '09'
					},
					{
						name: '十月',
						value: '10'
					},
					{
						name: '十一月',
						value: '11'
					},
					{
						name: '十二月',
						value: '12'
					}
				],
				time: [],
				info: {
					pageNo: 1,
					pageSize: 12,
					matchYear: undefined,
					matchMonth: undefined,
					matchAddressDistrict: undefined,
					matchProperty: '',
					matchLevel: '',
					matchSport: '',
					matchName: '',
					matchSportCollection:''
				},
				total: 0,
				data: [],
				columnId: '',
				columnPid: '',
				sportsList: [],
				sportsindex: 0,
				showFilterOptions: false,
				matchAddresslist:[]
			}
		},
		filters: {
			format(x) {
				if (x != null && x != '') {
					return x.split(" ")[0]
				}
			}
		},
		created() {
			this.info.matchName = this.$route.query.matchName || ''
			this.yearList()
			// this.getRefereeSports()
			this.getMatchBasicInfo()
		    this.areaList()

		},
		methods: {
			areaList() {
				this.$api.areaList().then(res => {
					this.matchAddresslist = res.data.data.result
				})
			},
			yearList(){
				this.$api.yearList(this.info).then(res => {
					this.time = res.data.data
				})
			},
			match() {
				this.info.pageNo = 1
				this.getMatchBasicInfo()
			},
			match1() {
				this.info.pageNo = 1
				this.getMatchBasicInfo()
			},
			getevent(index, item) {
				this.info.pageNo = 1
				this.levelindex = index
				this.info.matchLevel = item.itemValue
				this.info.pageNo = 1
				this.getMatchBasicInfo()
			},
			getRefereeSports() {
				this.$api.getRefereeSports({
					dictId: '1663440116581470209'
				}).then(res => {
					this.sportsList = res.data.data.result
				})
			},
			column(e, id, data) {
				this.columnIndex = e
				this.sportsindex = 0
				this.info.matchSport = ''
				this.info.pageNo = 1
				this.info.matchSportCollection = data
				this.getMatchBasicInfo()
				this.$api.getRefereeSportsDictItem({
					dictId: id
				}).then(res => {
					this.sportsList = res.data.data.result
				})
			},
			getsports(index, item) {
				this.info.pageNo = 1
				this.sportsindex = index
				this.info.matchSport = item.item_value
				this.getMatchBasicInfo()
			},
			searchfor() {
				this.info.pageNo = 1
				this.getMatchBasicInfo()
			},
			getMatchBasicInfo() {
				this.$api.getMatchBasicInfo(this.info).then(res => {
					res.data.data.result.records.forEach(item => {
						item.matchAddressStadium = item.addresses.map(item => {
							return item.matchAddressStadium
						}).join('、')
						this.$api.getFile({
							objectName: item.imgs[0]
						}).then(res => {
							this.$convert(res.data.data.result.url).then((fileUrl) => {
								item.image = fileUrl
								this.$forceUpdate()
							})
						})
					})
					this.data = res.data.data.result.records
					this.total = res.data.data.result.total
				})
			},
			currentchange(val) {
				this.info.pageNo = val
				this.getMatchBasicInfo()
			},
			getdetails(row) {
				this.$router.push({
					path: '/h5/H5details',
					query: {
						id: row.id,
						refresh: new Date().getTime(),
						type: 1,
						columnId: this.id,
						columnPid: this.pid
					}
				});
			},
			toggleFilterOptions() {
				this.showFilterOptions = !this.showFilterOptions;
			}
		}
	}
</script>

<style scoped lang="scss">
	.input {
		margin: 22PX 28PX;
	}

	.el-input-group {
		background: #E1EEFF;
		border-radius: 20PX;
	}

	.input ::v-deep .el-input__inner {
		height: 34PX;
		height: 39PX;
		background: #E1EEFF;
		border: none;
		border-top-left-radius: 20PX;
		border-bottom-left-radius: 20PX;

	}

	.input ::v-deep .el-input-group__append {
		border: none;
		color: #fff;
		width: 10PX;
		height: 39PX;
		border-radius: 20PX;
		background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
	}

	.input ::v-deep .el-input-group__append button.el-button {
		font-weight: 400;
		font-size: 15PX !important;
	}

	.input ::v-deep input::-webkit-input-placeholder {
		color: #8EB1F6;
	}

	.input ::v-deep input::-moz-placeholder {
		color: #8EB1F6;
	}

	.screenbox {
		display: flex;
		align-items: center;
		margin-left: 28PX;

		img {
			width: 30PX;
			height: 30PX;
		}

		p {
			font-size: 14PX;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #0576F0;
			margin-left: 10PX;
		}
	}

	.nian {
		display: flex;
		margin: 8PX 27PX 0 27PX;
		align-items: center;
		justify-content: space-between;

		p {
			font-size: 14PX;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000;
		}

		.select ::v-deep .el-select .el-input .el-input__inner {
			border-color: transparent !important;
			background-color: transparent !important;
			box-shadow: none !important;
			color: #000;
		}

		.select ::v-deep .el-select .el-input,
		.select ::v-deep .el-select-dropdown .el-select-dropdown__item.selected {
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		}

		.select ::v-deep .el-select .el-input .el-select__caret.is-reverse {
			transform: rotateZ();
		}

		.select ::v-deep .el-select .el-input .el-select__caret {
			color: #C0C4CC;
			font-size: 14PX;
			transition: transform .3s;
			transform: rotateZ(89deg);
			cursor: pointer;
			left: 15PX;
			position: relative;
		}

		.select ::v-deep input::-webkit-input-placeholder {
			color: #000;
		}

		.select ::v-deep .el-select .el-input .el-select__caret {
			color: #000;
		}
	}

	.xian {
		margin: 0 28PX;
		height: 1PX;
		background: rgba(194, 194, 194, 0.2);
	}

	.event {
		margin: 11PX 28PX 0;
		display: flex;
		flex-wrap: wrap;

		.eventbut {
			padding: 4PX 10PX;
			background: #EFF6FF;
			border-radius: 5PX;
			margin-right: 21PX;
			margin-bottom: 11PX;
		}

		.eventbut2 {
			border: 1PX solid rgba(30, 113, 239, 0.5);
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #1E71EF;
		}
	}

	.xianm {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 14PX 28PX 0;
		background: #F0F7FF;
		border: 1PX solid #4497F8;
		border-radius: 9PX;

		.name {
			display: flex;
			font-size: 13PX;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #1E71EF;
			padding: 10PX;
		}

		.name:nth-child(3n) {
			margin-right: 0;
		}

		.name2 {
			color: #FFFFFF;
			background: #0A6DF3;
			border-radius: 8PX;
			height: 100%;
			padding: 10PX;
		}
	}

	.xiaoxian {
		background: #F0F7FF;
		border-radius: 8PX;
		margin: 18PX 28PX 0;
		display: flex;
		flex-wrap: wrap;
		padding: 13PX 17PX 0 17PX;

		.name {
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			margin-right: 15PX;
			margin-bottom: 13PX;
		}
	}

	// .saishi {
	// 	margin: 18PX 28PX 0;
	// 	display: flex;
	// 	flex-wrap: wrap;
	// 	justify-content: space-between;

	// 	.saishixx {
	// 		width: 46%;
	// 		// margin-right: 21PX;
	// 		margin-bottom: 24PX;

	// 		.introduction {

	// 			.img {
	// 				width: 87.8%;
	// 				height: 103PX;
	// 				border-radius: 10PX 10PX 0PX 0PX;
	// 				margin-left: 7%;
	// 				margin-bottom: -28PX;
	// 			}
	// 		}

	// 		.saishibox {
	// 			.specific-box .name-container {
	// 				height: 35PX;
	// 				background: #F1F8FF;
	// 				font-size: 13PX;
	// 				font-weight: bold;
	// 				color: #164B92;
	// 				overflow: hidden;
	// 				display: flex;
	// 				align-items: center;

	// 				.name {
	// 					width: 100%;
	// 					overflow: hidden;
	// 					text-overflow: ellipsis;
	// 					display: -webkit-box;
	// 					-webkit-line-clamp: 2;
	// 					-webkit-box-orient: vertical;
	// 					// padding: 0 5PX;
	// 				}
	// 			}
	// 			.line{
	// 				// width: calc(100% - 22PX);
	// 				// padding-right: 11PX;
	// 			}

	// 			img {
	// 				width: 100%;
	// 				height: 203PX;
	// 			}
				
	// 			.specific-box {
	// 				width: calc(37% - 11PX);
	// 				height: 73PX;
	// 				z-index: 999;
	// 				position: absolute;
	// 				margin-top: 28PX;
	// 				margin-left: 11PX;
	// 			}

	// 			.specific-box .specific {
	// 				display: flex;
	// 			}

	// 			.specific-box .specific .img {
	// 				width: 22PX;
	// 				height: 19PX;
	// 				margin-top: 5PX;
	// 			}

	// 			.specific-box .specific .information {
	// 				margin-left: 11PX;
	// 			}

	// 			.specific-box .specific .information .information-title {
	// 				font-size: 13PX;
	// 				font-weight: bold;
	// 				color: #164B92;
	// 			}

	// 			.specific-box .specific .information .information-date {
	// 				font-size: 12PX;
	// 				font-weight: 500;
	// 				color: #333333;
	// 				margin-top: 1PX;
	// 				overflow: hidden;
	// 				white-space: nowrap;
	// 				text-overflow: ellipsis;
	// 				-o-text-overflow: ellipsis;
	// 				width: 80PX;
	// 			}

	// 			.specific-box .button {
	// 				width: 181PX;
	// 				height: 36PX;
	// 				background: #0A6DF3;
	// 				border-radius: 18PX;
	// 				font-size: 15PX;
	// 				font-weight: bold;
	// 				color: #FFFFFF;
	// 				line-height: 36PX;
	// 				text-align: center;
	// 				margin-left: 42PX;
	// 				margin-top: 15PX;
	// 			}

	// 			.specific-box .line {
	// 				width: 139;
	// 				height: 1PX;
	// 				background: #DADADB;
	// 				opacity: 0.63;
	// 				margin: 4PX 0 3PX 0;
	// 			}
	// 		}
	// 	}

	// 	.saishixx:nth-child(2n) {
	// 		margin-right: 0;
	// 	}
	// }
	
	.course-box {
		display: flex;
		flex-wrap: wrap;
		// justify-content: flex-start;
		justify-content: space-between;
		margin: 24PX 10PX 0;
	}
	
	.course {
		width: 48%;
		// height: 241PX;
		border-radius: 17PX;
		margin-bottom: 16PX;
	}
	
	.course:nth-child(2n) {
		margin-right: 0;
	}
	
	.introduction {
		cursor: pointer;
	}
	
	.introduction img {
		width: 100%;
		height: 118PX;
		border-radius: 17PX 17PX 0PX 0PX;
	
	}
	
	
	.name-container {
		height: 42PX;
		background: rgba(236, 241, 253, 0.65);
		font-size: 13PX;
		font-weight: bold;
		color: #164B92;
		overflow: hidden;
		display: flex;
		align-items: center;
	}
	
	.name-container .name {
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		padding: 0 5PX;
	}
	
	// .introduction .name {
	// 	position: absolute;
	// 	width: 45.5%;
	// 	height: 42PX;
	// 	background: rgba(0, 0, 0, 0.67);
	// 	font-size: 14PX;
	// 	font-weight: bold;
	// 	color: #FFFFFF;
	// 	text-align: center;
	// 	margin-top: 76PX;
	// 	overflow: hidden;
	// 	text-overflow: ellipsis;
	// 	display: -webkit-box;
	// 	-webkit-line-clamp: 2;
	// 	-webkit-box-orient: vertical;
	// 	padding: 0 5PX;
	// 	padding-top: 2PX;
	// }
	
	.specific-box {
		// height: 122PX;
		background: rgba(236, 241, 253, 0.65);
		border-radius: 0PX 0PX 17PX 17PX;
		cursor: pointer;
	}
	
	.specific-box .specific {
		display: flex;
	}
	
	.specific-box .specific .img {
		width: 23PX;
		height: 23PX;
		margin-top: 6PX;
		margin-left: 14PX;
	}
	
	.specific-box .specific .information {
		margin-left: 11PX;
	}
	
	.specific-box .specific .information .information-title {
		font-size: 12PX;
		font-weight: bold;
		color: #164B92;
		margin-top: 6PX;
	}
	
	.specific-box .specific .information .information-date {
		width: 115PX;
		font-size: 10PX;
		font-weight: 500;
		color: #333333;
		margin-top: 6PX;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
	}
	
	.specific-box .button {
		background: #0A6DF3;
		border-radius: 18PX;
		font-size: 12PX;
		font-weight: bold;
		color: #FFFFFF;
		text-align: center;
		margin: 5PX 30PX;
		padding: 3PX 20PX;
	}
	
	.line {
		width: 163PX;
		height: 1PX;
		background: #DADADB;
		opacity: 0.63;
		margin-top: 7PX;
	}

	.paging {
		margin-bottom: 28PX;
		margin-left: 28PX;
		display: flex;
		align-items: center;
	}

	.paging span {
		margin-right: 5PX;
	}

	.el-link.el-link--primary {
		color: #0066FF;
	}
</style>
